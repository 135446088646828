import { equals, map, pluck } from 'ramda'
import { getFromFormBySonataSelector, stringValueOfFormField } from '@/util/sonataHelper'
import { requireFormField, unrequireFormField } from './MaskType'

const $ = window.$
const DATE_NOT_SET_YET = 'The date of the course has not yet been set'
const DATE_NOT_FIXED = 'The course date is not fixed (participation is possible at any time)'
const DATE_MODULAR = 'Modular'


/**
 * Mark an element as required if all specified criteria from the predefined map
 * (`data-unrequire-if`) match.
 *
 * Also hides possible error message and the required icon of the label. THIS
 * ONLY WORKS FOR COLLECTIONTYPE FIELDS.
 *
 * Input:
 *     - data-unrequire-if :: [{ selector: String, value: String }]
 */
const updateRequiredStatusOfDateField = ($el) => {
    const $errors = $el.parent().find('.has-error__collection-type')
    const $requiredIcon = $el.parent().find('> label .required-icon')

    // data :: [{ selector: String, value: String }]
    const data = $el.data('unrequire-if')

    // _$fields :: [jQuery]
    const _$fields = map(
        getFromFormBySonataSelector($el),
        pluck('sonataSelector', data),
    )

    // _fieldValues :: [String]
    const _fieldValues = map(
        x => x.toString(),
        pluck('value', data),
    )

    // _currentFormValues :: [String]
    const _currentFormValues = map(stringValueOfFormField, _$fields)

    // isNotRequired :: [String] -> Bool
    const isNotRequired = equals(_fieldValues)

    // updateRequiredStatus :: * -> undefined
    const updateRequiredStatus = () => {
        if (isNotRequired(_currentFormValues) || DATE_NOT_SET_YET === _currentFormValues[0] || DATE_NOT_FIXED === _currentFormValues[0] || DATE_MODULAR === _currentFormValues[0]) {
            unrequireFormField($el)
            $errors.fadeOut()
            $requiredIcon.fadeOut()
        } else {
            requireFormField($el)
            $errors.fadeIn()
            $requiredIcon.fadeIn()
        }
    }

    _$fields.forEach(($field, i) => $field.on('change', (e) => {
        _currentFormValues[i] = stringValueOfFormField($field)
        updateRequiredStatus()
    }))

    updateRequiredStatus()
}

export default {
    init (root = document.body) {
        Array.from(document.querySelectorAll('input.js-unrequire-if'))
            .forEach(el => updateRequiredStatusOfDateField($(el)))
    },
}
