/**
 * Implementation for Sonata\AdminBundle\Form\Type\ChoiceFieldMaskType;
 *
 * https://symfony.com/doc/master/bundles/SonataAdminBundle/reference/form_types.html#sonata-adminbundle-form-type-choicefieldmasktype
 *
 * Enhancements:
 * - also works for radio button groups and groups of checkboxes
 * - map property paths can match all values that are not null
 *     '*NOTNULL*' => ['testimonialTextDe', ...]
 * - map property paths can be nested:
 *    'someValue' => ['../route', 'address/parameters'],
 * - map property paths may contain a wildcard character
 *     - false => ['priceIncludes', 'dates / * / costs'],
 *     - this is usually required if the field is contained in a CollectionType
 * - map property does not need to specify all available values
 * - accounts for the case that a mask type can be masked by another mask type
**/

import {
    __, append, clone, chain, compose, concat, curry, drop, dropWhile,
    difference, equals, find, filter, ifElse, is, isEmpty, length, map,
    propOr, reduce, reject, replace, split, startsWith, values, unless,
    union, when,
} from 'ramda'

import EventBus from '@/util/EventBus'
import {
    valueOfFormField, belongsToSelect2, computeFieldId, isImageCropHelperField,
    getMainFormName, getFieldName, isVichFiledWithUpload, findMatchingElements,
} from '@/util/sonataHelper'
import MaskedFields from './MaskedFields'

const $ = window.$
const selectFieldsNameLength = 13 // Length of _selectHskHwk


/**
 * For a sonata container element: Get the associated form field. Checkbox/Radio
 * buttons group are marked with the class "js-checkbox-group" and are treated as
 * one field (not as many input[type="radio"] or input[type="checkbox"] fields).
 *
 * getInnerFields :: jQuery Elem jQ => jQ -> [jQ]
 */
const getInnerFields = compose(
    filter($x => !belongsToSelect2($x) && !isImageCropHelperField($x)),
    $elems => $elems.map((_, elem) => $(elem)).get(), // jquery ... *sigh
    ifElse(
        $elem => $elem.find('.js-checkbox-group').length > 0, // case radio/checkbox button group
        $elem => $elem.find('.js-checkbox-group'),
        $elem => $elem.find('input,select,textarea'),
    ),
)

// isHidden :: jQuery Elem -> Boolean
const isHidden = $field => $field.attr('data-is-hidden') === 'true'

// containerSelectorToFormFieldSelector :: String -> String
const containerSelectorToFormFieldSelector = compose(
    concat('#'),
    drop(length('sonata-ba-field-container-')),
    dropWhile(equals('#')),
)

/**
 * Map a sonata container field (those with id of the form
 * #sonata-ba-field-container-s5a9fc837a5f1b_upload_imageCaption) to
 * a sonata form field (those with id of the form
 * s5a9fc837a5f1b_upload_imageCaption).
 *
 * @sig jQuery Elem jQ => jQ -> jQ
 */
const containerToField = compose(
    selector => {
        const $x = $(selector)
        if (!$x.length) {
            throw Error(`Element ${selector} could not be found. There is likely an error with the DOM or in the definition of a MaskType.`)
        }

        return $x
    },
    containerSelectorToFormFieldSelector,
    $x => $x.attr('id'),
)

/**
 * Convert a sonata id to a dot path that can be processed by the
 * Symfony PropertyAccessor.
 *
 * @sig String -> String
 * @example
 * sonataIdToDotPath('s5bc86276546f6_adressdaten_contactEmail_0_bla')
 * // 'adressdaten.contactEmail[0].bla'
 * sonataIdToDotPath('s5bc86276546f6_adressdaten_street')
 * // 'adressdaten.street'
 * sonataIdToDotPath('s5bc86276546f6_kursbeschreibung__typeOfProgramme')
 * // 'kursbeschreibung.typeOfProgramme'
 */
const sonataIdToDotPath = compose(
    when(startsWith('.'), drop(1)),
    reduce((dotPath, str) => concat(dotPath, isNaN(str) ? `.${str}` : `[${str}]`), ''),
    split('_'),
    drop(1),
    dropWhile(x => x !== '_'),
    replace(/__/g, '_'), // accounts for __ in sonata ids. This happens if you
    // add a field like this. ->add('kursbeschreibung.typeOfProgramme', ...) :/
)

/**
 * Map a sonata container field (those with id of the form
 * #sonata-ba-field-container-s5a9fc837a5f1b_upload_imageCaption) to the
 * dotPath of the form type, in this case `upload.imageCaption`.
 *
 * @sig jQuery elem jQ => jQ -> String
 */
const containerToSonataDotPath = compose(
    sonataIdToDotPath,
    $x => $x.attr('id'),
    containerToField,
)

// requireFormField :: jQuery -> *
export const requireFormField = ($elem) => {
    if ($elem.attr('data-required') === 'true' && (!$elem.hasClass('required-field') || $elem.attr('required') !== 'required')) {
        $elem.addClass('required-field')
        $elem.attr('required', 'required')
        EventBus.$emit('setRequiredMaskField', $elem)
    }
}

// unrequireFormField :: jQuery -> *
export const unrequireFormField = ($elem) => {
    if ($elem.attr('required') !== undefined || $elem.hasClass('required-field')) {
        $elem.removeClass('required-field')
        $elem.removeAttr('required')
        $elem.attr('data-required', true)
        EventBus.$emit('setUnrequiredMaskField', $elem)
    }
}

// revealFormField :: jQuery Elem -> undefined
const revealFormField = ($field) => {
    $field.removeAttr('data-is-hidden')
    $field.fadeIn()

    getInnerFields($field).forEach(requireFormField)
}

// hideFormField :: jQuery Elem -> undefined
const hideFormField = ($field) => {
    $field.attr('data-is-hidden', 'true')
    $field.fadeOut()
    EventBus.$emit('hideMaskField', containerToField($field))

    getInnerFields($field).forEach(unrequireFormField)
}

/**
 * A mask type is a form Element (select field, group of radio/checkbox buttons)
 * that hides or shows other form fields dependent on the current form value.
 *
 * data-main-form-name: The name of the form element
 * data-field-name: The name of the form field.
 * data-field-map: A map of the form { formValue: [String]}
 */
const makeMaskType = ($maskType) => {
    const mainFormName = [...new Set(getMainFormName($maskType).split('_'))].join('_') // Avoid of duplicate parts names for fields of the file type lock
    const maskFieldName = getFieldName($maskType)
    const $formElement = $maskType.prop('tagName') === 'INPUT'
        ? $maskType
        : $(`#${mainFormName}${maskFieldName}`)

    // computeIdSelector :: String -> String
    const computeIdSelector = field =>
        `#sonata-ba-field-container-${computeFieldId(mainFormName, field)}`

    // example: { 1: ['___/text', '___/title'], 2: [] }
    // formValueToIdMap :: { k: [String] }
    const formValueToIdMap = map(map(computeIdSelector), $maskType.data('field-map'))

    // allFieldsIds :: [String]
    const allFieldIds = reduce(union, [], values(formValueToIdMap))

    // allFields :: * -> [String]
    const allFields = () => clone(allFieldIds)

    let allDependentFieldIds = []
    if (undefined !== $maskType.data('map-by-many-fields')) {
        allDependentFieldIds = map((computeIdSelector), $maskType.data('map-by-many-fields'))
    }

    /**
     * Get All masked fields for a form field value.
     *
     * '*NOTNULL*' is a wildcard character for non empty form values.
     *
     * @sig String -> [String]
     */
    const getFieldsForValue = value => compose(
        when(
            _ => !isEmpty(value) || isVichFiledWithUpload($formElement),
            union(propOr([], '*NOTNULL*', formValueToIdMap)),
        ),
        propOr([], __, formValueToIdMap),
    )(value)

    /**
     * Get all form field selector for the elements that are visisble according
     * to the current form value
     *
     * @sig * -> [String]
     */
    const visibleFields = () =>
        unless(is(Array), x => [x], valueOfFormField($formElement))
            .reduce((acc, formValue) => union(acc, getFieldsForValue(formValue)), [])

    // belongsToSameMask :: jquery Elem -> Boolean
    const belongsToSameMask = ($formContainer) =>
        $formElement.is($formContainer.find('.js-mask-type')) && $formElement.attr('id').substr($formElement.attr('id').length - selectFieldsNameLength) !== '_selectHskHwk' // _selectHskHwk mask belongs to 2 masks "selectHskHwk, courseDate" (selectHskHwk mask is located in courseDate) but fields belong only to selectHskHwk mask

    return {
        visibleFields,
        allFields,
        belongsToSameMask,
        getRoot: () => $formElement,
        getDependentFields: () => allDependentFieldIds,
        getName: () => maskFieldName,
    }
}

export default (() => {
    /**
     * We need to keep a list of all created masks for the case that new elements
     * are inserted into the dom that need to be masked by existing masks.
     *
     * E.g. Consider a wildcard selector: `costs_*_name`
     *    - an element of the form costs_5_name can be added via collection Type
     */
    const allMasks = []

    const init = (root = document.body) => {
        const masks = Array.from(root.querySelectorAll('.js-mask-type:not(.select2-container)'))
            .map(x => makeMaskType($(x)))

        // findMatchingMask :: Mask m => jQuery Elem -> m | undefined
        const findMatchingMask = ($elem) => find(m => m.belongsToSameMask($elem), masks)

        /**
         * Recursively apply method of mask to get all selected form elements.
         *
         * This recursion is required for the case that a mask type points to
         * another mask type.
         *
         * @sig Mask m => String -> m -> [jQuery Elem]
         */
        const getRecursive = curry((methodName, mask) => compose(
            reduce(
                (acc, $elem) => {
                    const match = findMatchingMask($elem)

                    return match
                        ? union(acc, append($elem, getRecursive(methodName, match)))
                        : append($elem, acc)
                },
                [],
            ),
            chain(findMatchingElements),
        )(mask[methodName]()))

        // getAllFields :: Mask m => m -> [jQuery Elem]
        const getAllFields = getRecursive('allFields')

        // getCurrentVisibleFields :: Mask m => m -> [jQuery Elem]
        const getCurrentVisibleFields = compose(reject(isHidden), getAllFields)

        // getVisibleFields :: Mask m => m -> [jQuery Elem]
        const getVisibleFields = getRecursive('visibleFields')

        const dependentFieldsToShow = []
        const dependentFieldsIds = []
        // applyMask :: Mask m => m -> undefined
        const applyMask = (mask) => {
            const maskName = mask.getName()
            const currentVisibleFields = getCurrentVisibleFields(mask)
            const newVisibleFields = getVisibleFields(mask)
            const fieldsToReveal = difference(newVisibleFields, currentVisibleFields)
            const fieldsToHide = difference(currentVisibleFields, newVisibleFields)

            mask.getDependentFields().forEach((field) => {
                if ($.inArray(field, dependentFieldsIds) === -1) {
                    dependentFieldsIds.push(field)
                }
                const isDependentField = dependentFieldsToShow.find(function (value) {
                    if (undefined !== value && undefined !== value.key && undefined !== value.value &&
                        maskName === value.key && field === value.value) {
                        return true
                    }
                })
                const isDependentFieldVisible = newVisibleFields.find(function (value) {
                    if (undefined !== value && field === '#' + value.attr('id')) {
                        return true
                    }
                })
                if (undefined === isDependentField && undefined === isDependentFieldVisible) {
                    dependentFieldsToShow.push({ key: maskName, value: field })
                }
            })

            for (let i = fieldsToReveal.length - 1; i >= 0; i--) {
                const index = $.inArray('#' + fieldsToReveal[i].attr('id'), dependentFieldsIds)
                const isDependentFieldToReveal = dependentFieldsToShow.find(function (value) {
                    if (undefined !== value && undefined !== value.key && undefined !== value.value &&
                        maskName === value.key && '#' + fieldsToReveal[i].attr('id') === value.value) {
                        return true
                    }
                })
                if (undefined !== fieldsToReveal[i] && index !== -1 && (undefined !== isDependentFieldToReveal || dependentFieldsToShow.length > 0)) {
                    const intIntdex = dependentFieldsToShow.findIndex(function (value) {
                        if (undefined !== value && undefined !== value.key && undefined !== value.value &&
                            maskName === value.key && '#' + fieldsToReveal[i].attr('id') === value.value) {
                            return true
                        }
                    })
                    delete dependentFieldsToShow[intIntdex]
                    fieldsToReveal.splice(i, 1)
                }
                const hidesDependentFields = dependentFieldsToShow.filter(element => {
                    if (Object.keys(element).length !== 0) {
                        return true
                    }
                    return false
                })
                if (hidesDependentFields.length === 0) {
                    dependentFieldsIds.forEach((field) => {
                        fieldsToReveal.push($(field))
                    })
                }
            }

            fieldsToReveal.forEach(revealFormField)
            fieldsToHide.forEach(hideFormField)
            MaskedFields.add(map(containerToSonataDotPath, fieldsToHide))
            MaskedFields.remove(map(containerToSonataDotPath, fieldsToReveal))
        }

        /**
         * Hide all fields that are masked out by Mask Type fields.
         *
         * IMPORTANT: For the first time, you must not call applyMask
         * from mask types that are masked out by other mask types because
         * you might accidentally show hidden fields.
         * - this happens if a hidden child mask calls applyMask AFTER its parent
         */
        const hideAllMaskedFields = () => {
            // allFieldsFromAllMasks :: [jQuery Elem]
            const allFieldsFromAllMasks = masks.map(getAllFields).reduce(union, [])

            // rootMasks :: [Mask]
            const rootMasks = reject(mask => {
                const foundField = find($field => {
                    return mask.belongsToSameMask($field)
                }, allFieldsFromAllMasks)

                if (undefined !== foundField && foundField.attr('id') && foundField.attr('id').endsWith('_modularPrograms')) { // modularPrograms must also be set as a root; it will be set separately because it is located at the second level of a nested structure.
                    return false
                }

                return foundField !== undefined
            }, masks)

            rootMasks.forEach(applyMask)
        }

        allMasks.forEach(applyMask) // apply all existing masks first
        hideAllMaskedFields()

        masks.forEach((mask) => {
            const $root = mask.getRoot()
            if ($root.prop('tagName') === 'INPUT' || $root.prop('tagName') === 'TEXTAREA') {
                $root.on('input', () => applyMask(mask))
            }
            $root.on('change', () => applyMask(mask))

            allMasks.push(mask)
        })
    }

    return { init }
})()
