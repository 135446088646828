const adjustSubjectKey = () => {
    document.querySelectorAll('.subject-key-header-label').forEach(function (label) {
        label.addEventListener('click', function () {
            const targetSelector = label.getAttribute('data-toggle-target')
            if (targetSelector) {
                const target = document.getElementById(targetSelector)
                if (target) {
                    target.classList.toggle('collapse')
                    updateSymbol(label, target.classList.contains('collapse'))
                }
            }
        })

        const targetSelector = label.getAttribute('data-toggle-target')
        if (targetSelector) {
            const target = document.getElementById(targetSelector)
            if (target) {
                updateSymbol(label, target.classList.contains('collapse'))
            }
        }
    })

    function updateSymbol (label, isCollapsed) {
        const text = label.textContent.replace(/^[-+]\s*/, '').trim()
        label.textContent = (isCollapsed ? '+ ' : '- ') + text
    }

    const checkboxes = document.querySelectorAll('.subject-key-list input[type="checkbox"]')
    if (checkboxes.length === 0) {
        return
    }
    const maxSelection = 4
    const selectedContainer = document.querySelector('.selected-subject-key')
    const divElement = selectedContainer.closest('div.form-group')
    const fieldLabel = divElement.querySelector('label')
    const warnMessage = divElement.querySelector('.sonata-ba-field-error-messages')
    updateCheckboxes()

    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', function () {
            updateCheckboxes()
        })
    })

    function updateSelectedText () {
        selectedContainer.innerHTML = ''
        document.querySelectorAll('.subject-key-list input[type="checkbox"]:checked').forEach(checkbox => {
            const studyAreaElement = checkbox.closest('.subject-key-header-label__study-area')
            const subjectGroupElement = checkbox.closest('.subject-key-header-label__subject-group')
            const checkboxLabel = removeSymbolFromStart(studyAreaElement.querySelector(`label[for="${checkbox.id}"]`).textContent.trim())
            const studyAreaKey = removeSymbolFromStart(studyAreaElement.querySelector('label').textContent.trim())
            const subjectGroupKey = removeSymbolFromStart(subjectGroupElement.querySelector('label').textContent.trim())

            const deselectSpan = document.createElement('span')
            deselectSpan.innerHTML = '<i class = "selected-subject-key-close"> </i>'
            deselectSpan.style.cursor = 'pointer'
            deselectSpan.dataset.checkboxId = checkbox.id

            deselectSpan.addEventListener('click', function () {
                const relatedCheckbox = document.getElementById(this.dataset.checkboxId)
                if (relatedCheckbox) {
                    relatedCheckbox.checked = false
                    updateCheckboxes()
                }
            })

            const subjectEntry = document.createElement('p')
            subjectEntry.innerHTML = `${subjectGroupKey} > ${studyAreaKey} > ${checkboxLabel}`
            subjectEntry.insertBefore(deselectSpan, subjectEntry.firstChild)
            selectedContainer.appendChild(subjectEntry)
        })
    }

    function removeSymbolFromStart (text) {
        return text.startsWith('+ ') || text.startsWith('- ') ? text.slice(2).trim() : text
    }

    function updateCheckboxes () {
        const checkedCount = document.querySelectorAll('.subject-key-list input[type="checkbox"]:checked').length

        checkboxes.forEach(checkbox => {
            if (checkedCount >= maxSelection && !checkbox.checked) {
                checkbox.disabled = true
                checkbox.style.cursor = 'not-allowed'
                checkbox.setAttribute('title', 'Es wurden bereits vier Fächer ausgewählt.')
            } else {
                checkbox.disabled = false
                checkbox.style.cursor = 'pointer'
                checkbox.removeAttribute('title')
            }
        })

        if (checkedCount > maxSelection) {
            this.checked = false
        }

        updateErrorState()
        updateSelectedText()
    }

    function updateErrorState () {
        const checkedCount = document.querySelectorAll('.subject-key-list input[type="checkbox"]:checked').length
        if (checkedCount === 0) {
            fieldLabel.classList.add('required-label')
            if (warnMessage) {
                warnMessage.classList.add('sonata-ba-field-error-messages-subject-key')
            }
        } else {
            fieldLabel.classList.remove('required-label')
            if (warnMessage) {
                warnMessage.classList.remove('sonata-ba-field-error-messages-subject-key')
            }
        }
    }
}

const subjectKey = {
    init () {
        adjustSubjectKey()
    },
}
export default subjectKey
