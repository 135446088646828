import { keys } from 'ramda'

import EventBus from '@/util/EventBus'
import CalendarJumpToDate from './CalendarJumpToDate'
import CopyFormFields from './CopyFormFields'
import UpdateCheckbox from './UpdateCheckbox'
import FormatInputField from './FormatInputField'
import UpdateDate from './UpdateDate'
import RestrictEintragsart from './RestrictEintragsart'
import FormTabs from './FormTabs'
import MaskType from './MaskType'
import UrlType from './UrlType'
import RequiredStatus from './UpdateRequiredStatusOfDates'
import ImageCropperType from './ImageCropperType'
import TextareaCounter from './TextareaCounter'
import FieldsToConfirm from './FieldsToConfirm'
import TriggerLockAction from './TriggerLockAction'
import DeleteFileButton from './DeleteFileButton'
import CheckCoordinator from './CheckCoordinator'
import SortableCollection from './SortableCollection'
import UpdateUpload from './UpdateUpload'
import UpdateHelpText from './UpdateHelpText'
import UpdateAcademyStatus from './UpdateAcademyStatus'
import UpdateFields from './UpdateFields'
import FormConfirmSubmit from './FormConfirmSubmit'
import { onConfirm } from '@/util/confirm'
import CourseLeaveMessage from './CourseLeaveMessage'
import CriteriaCheck from './CriteriaCheck'
import SubjectKey from './SubjectKey'


const $ = window.$


/**
 * Automatically resize textarea on input
 */
const autoResizeTextarea = (root = document.body) => {
    $(root).find('textarea').each(function () {
        const $el = $(this)
        const offset = this.offsetHeight - this.clientHeight + 25
        let currentValue = null

        const resizeTextarea = () => {
            /**
             * Early exit if value did not change to prevent unnecessary
             * rerender when `height: auto;` is set in firefox.
             *
             * @see https://extranet.snoopmedia.com/issuetracker/view.php?id=36009
             */
            if (currentValue === $el.val()) return

            currentValue = $el.val()

            const $parent = $el.closest('.form-group')

            /**
             * Prevents a firefox bug that loses the scroll position when
             * the child textarea's height collapses.
             */
            $parent.css('min-height', $parent.outerHeight() + offset)

            $el
                // collapse textarea so that browser can determine scrollHeight
                .css('height', 'auto')
                // now reset the height
                .css('height', Math.max($el.prop('scrollHeight') + offset, 50))

            $parent.css('min-height', '')
        }

        $(this).each(resizeTextarea)
        $(this).on('keyup input', resizeTextarea)

        /**
         * Constantly update the height of the textarea, as there is no
         * (reliable) way of checking whether the textarea has been modified
         * by other javascript methods.
         */
        setInterval(resizeTextarea, 500)
    })
}

/**
 * Disable a field if checkbox is checked and enable otherwise.
 */
const disabledTargetField = (root = document.body) => {
    Array.from(root.querySelectorAll('.js-disable-target')).forEach((checkbox) => {
        const $checkbox = $(checkbox)
        const $target = $(`#${$checkbox.data('target-id')}`)

        const changeFieldStatus = () => {
            if ($checkbox.is(':checked')) {
                $target.attr('readonly', 'readonly')
            } else {
                $target.removeAttr('readonly')
            }
        }

        changeFieldStatus()
        $checkbox.on('change', () => changeFieldStatus())
    })
}

/**
 * Init Rich Text Editor Fields. Currently this is done in twig templates of
 * SonataFormatterBundle. But we need a change event on the textarea in order
 * for the FormTabs to update if a value is entered.
 */
const initRTE = (root = document.body) => {
    const CKEDITOR = window.CKEDITOR

    keys(CKEDITOR.instances)
        .filter(id => $(root).find(`#${id}`).length > 0)
        .forEach((id) => {
            const instance = CKEDITOR.instances[id]
            const $textarea = $(`#${id}`)
            instance.on('change', () => {
                $textarea.html(instance.getData())
                $textarea.trigger('change')
            })
        })

    // display modal if user types in too much characters in a cke textarea
    // data.detail constains: currentLenght, maxLength, isOnInit
    document.addEventListener('ckeditor.wordcount.charCountGreaterThanMaxLengthEvent', (data) => {
        if (data.detail.isOnInit) {
            return // skip because user did not interact with rte
        }

        const title = 'Zeichenbeschränkung'
        const msg = `Das Zeichenlimit für dieses Feld beträgt ${data.detail.maxLength}.`

        onConfirm({ msg, title, cancel: '', submit: 'Ok' }, () => {})
    }, false)
}

/**
 * Set the coordinator's value for locked fields
 */
const setDefaultValue = (root = document.body) => {
    Array.from(root.querySelectorAll('.js-default-value')).forEach((element) => {
        const $element = $(element)
        const defaultValue = $element.data('default-value')

        $element.val(defaultValue)
    })
}

export default (() => {
    const doInit = (root = document.body) => {
        autoResizeTextarea(root)
        TextareaCounter.init(root)
        disabledTargetField(root)
        UrlType.init(root)
        CopyFormFields.init(root)
        FormatInputField.init(root)
        UpdateCheckbox.init(root)
        UpdateDate.init(root)
        UpdateUpload.init(root)
        UpdateHelpText.init(root)
        UpdateAcademyStatus.init(root)
        RestrictEintragsart.init(root)
        FormTabs.init(root) // init before MaskType
        FieldsToConfirm.init(root) // init after FormTabs and before MaskType
        MaskType.init(root) // init after FieldsToConfirm and FormTabs
        ImageCropperType.init(root)
        CalendarJumpToDate.init(root)
        DeleteFileButton.init(root)
        CheckCoordinator.init(root)
        TriggerLockAction.init(root)
        CourseLeaveMessage.init(root)
        initRTE(root)
        RequiredStatus.init(root)
        SortableCollection.init(root)
        FormConfirmSubmit.init(root)
        setDefaultValue(root)
        CriteriaCheck.init()
        UpdateFields.init()
        SubjectKey.init()
    }

    EventBus.$on('addCollectionTypeElement', ($element) => {
        $element.get().forEach(doInit)
    })

    $(document).on('shown.bs.tab', () => {
        TextareaCounter.init()
    })

    const init = (root = document.body) => doInit(root)

    return { init }
})()
